.search-on {

  .toggle-search {
    position: absolute !important;
    right: 2px;
    width: 30px;
    bottom: -45px;
    z-index: 1;
    transition: bottom $transition-standard;

    &:after {
      content: '\f002';
      display: inline-block;
      position: absolute;
      font-family: FontAwesome;
      right: 0;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #fff;
      background-color: $accent-color;
      border-radius: 100px;
      top: calc(50% - 15px);
      transition: background-color $transition-standard;
      transition: transform .2s ease-in-out;
    }

    &:hover,
    &.search-box-initiated {
      cursor: pointer;

      &:after {
        background-color: $accent-color-darken-5;
        transform: scale(1.1);
      }

    }

  }

  .nav-fixed & {

    .toggle-search { bottom: -25px; }

  }

}
