//--- GENERAL SITE STYLES ---//

// User Mode
.user_mode {

  &.home #panelTwo { display: none; }

  .construction-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99999;

    .column { padding: 0; }

    .pageElement { margin: 0; }

    .column,
    .pageEl,
    .pageElement { height: 100%; }

    video { height: 100%; }

  }

  .theme-nav-item.home {
    width: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
  }

  // Custom Backgrounds
  .gray-bg {
    background-color: #bcbcbc;

    p { color: #fff; }

  }

  .blue-white {
    background: #daf0f3;
    background: -webkit-linear-gradient(#daf0f3, #fff);
    background: -o-linear-gradient(#daf0f3, #fff);
    background: -moz-linear-gradient(#daf0f3, #fff);
    background: linear-gradient(#daf0f3, #fff);
  }

  // Home Styles
  &.home {

    #siteContainer, #siteContainer #siteContainer.container-fluid { padding: 0; }

    #panelTwo { padding: 0; }

  }

  .inner-layout {
    max-width: $maxWidth;
    margin: auto;

    @media only screen and (max-width: 1300px){ max-width: 1000px; }

  }

  .full-width .inner-layout {
    max-width: 100%;

    .column { padding: 0; }

    .pageElement { margin: 0; }

  }

}

// Edit Mode
.edit_mode {}

// Assets
.footer-content {

  .heroPhotoElement img {
    max-width: 100px;
    display: inline-block;
  }

}

// Footer
#footer-content-container {
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 30px 0 50px;

  &:after {
    content: '';
    clear: both;
    display: block;
  }

  .pageEl {
    max-width: 25%;
    float: left;
    width: 100%;

    @media only screen and (max-width: 600px){
      max-width: 100%;
      text-align: center;
    }

    &:after {
      content: '';
      clear: both;
      display: block;
    }

  }

  .footer-logo {

    img {
      max-width: 100px;

      @media only screen and (min-width: 601px){
        margin: 0;
      }

    }

  }

  .textBlockElement {

    h4, a { color: white; }

    h4 {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 15px;
    }

    a {
      display: inline-block;
      padding-bottom: 5px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: .5px;
      font-size: 12px;
      transition: color $transition-standard;

      &:hover {
        text-decoration: none;
        color: $accent-color;
      }

    }

  }

  .sn-social-media-list {

    @media only screen and (min-width: 768px){ .sn-social-media-icon { margin: 0; } }

    @media only screen and (min-width: 601px){ text-align: right; }

  }

}

// Feature
.feature {

  &.has-countdown { padding-bottom: 82px; }

  .column {
    padding: 0;
    position: static;
  }

  .heroPhotoElement img { max-width: 800px; }

  .user_mode & .feature-count { position: absolute; }

  .feature-count {
    left: 0;
    bottom: -82px;
    width: 100%;

    .pageElement {
      margin: 0;
      padding: 0;
    }

    .countdownElement {

      &.countdown-dark,
      &.countdown-light {
        background: #a1a2a6;
        background: -webkit-linear-gradient(#c7cdcd, #a1a2a6);
        background: -o-linear-gradient(#c7cdcd, #a1a2a6);
        background: -moz-linear-gradient(#c7cdcd, #a1a2a6);
        background: linear-gradient(#c7cdcd, #a1a2a6);

        &:before,
        &:after { display: none; }

      }

      span {
        text-align: center;
        margin: 0;
        border-right: solid 1px #a1a1a1;
        padding: 0 15px 0 12px;

        @media only screen and (max-width: 600px){ padding: 0 5px 0 1px; }

        &:last-of-type { border-right: none; }

      }

      b {
        font-size: 28px;

        @media only screen and (max-width: 600px){ font-size: 16px; }

      }

      small {
        color: #5b5a5a;

        @media only screen and (max-width: 600px){ font-size: 9px; }

      }

      .feature-count-contents {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1200px;
        position: relative;
        padding: 20px 15px;
        margin: auto;

        .feature-count-title {
          width: calc(50% + 90px);
          margin: 0 3% 0 0;
          text-align: right;

          @media only screen and (max-width: 768px){ width: 50%; }

          h2 {
            padding: 0 0 0 90px;
            display: inline-block;
            position: relative;
            margin: 0;

            @media only screen and (max-width: 900px){ padding: 0; }
            @media only screen and (max-width: 600px){ font-size: 16px; }

            &:before {
              content: '';
              position: absolute;
              height: 82px;
              width: 75px;
              top: -27px;
              left: 0;
              background-image: url('../images/logo.png');
              background-position: center;
              background-size: 75%;
              background-repeat: no-repeat;

              @media only screen and (max-width: 900px){ display: none; }

            }

          }

          strong { color: $accent-color; }

        }

        abbr {
          width: calc(50% - 90px);
          padding: 0;
          margin: 0 0 0 3%;
          text-align: left;

          @media only screen and (max-width: 768px){ width: 50%; }

        }


      }

    }

  }

}

.feature-video-container {
  width: 100%;
  position: relative;

  .inner-layout {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: flex;
    -moz-display: flex;
    -webkit-display: flex;

    .column {
      align-self: center;
      -moz-align-self: center;
      -webkit-align-self: center;
      margin: auto;
      box-sizing: border-box;

      @media only screen and (max-width: 1024px){
        padding: 0 10%;
      }

    }

    h1 {
      color: white;
      font-weight: 400;
      letter-spacing: 1px;
    }

  }

}

.feature-video {
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  vertical-align: bottom;

  @media only screen and (max-width: 1024px){ opacity: 0; }

}


// CTA Link Menus
.user_mode .link-slider-container {

  // Desktop

  &:after {
    display: block;
    content: '';
    clear: both;
    width: 100%;
  }

  .column {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      clear: both;
    }

  }

  .link-slider-links {
    width: 90%;
    margin: 0 5% 15px;
    border-bottom: solid 4px rgba(255,255,255,.3);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 0;
    z-index: 1;
  }

  .linkElement.link-slider-link {
    float: left;
    width: auto;
    clear: none;

    h4 {

      a {
        background: transparent;
        display: inline-block;
        box-shadow: none;
        padding: 0;
        margin: 0 30px 0 0;
        font-size: 18px;
        letter-spacing: 1px;
        position: relative;
        color: #ffffff;
        font-weight: 700;
        border: none;
        border-radius: 0;

        @media only screen and (max-width: 1120px){ font-size: 14px; }

        &:after { display: none; }

        &:before {
          content: '';
          display: inline-block;
          height: 4px;
          width: 100%;
          position: absolute;
          left: 0;
          top: 33px;
          opacity: 0;
          background: rgba(255,255,255,.3);
          transition: opacity $transition-standard;
        }

        &:hover{

          &:before { opacity: 1; }

        }

      }

    }

  }

  // Mobile
  .info-link {
    background-size: 100%;
    background-position: center;

    .linkElement h4 {

      a {
        box-shadow: none;
        text-align: center;
        display: block;
        padding: 30px 15px;
        font-size: 20px;
        font-weight: 300;
        color: #ffffff;
        border: none;
        border-radius: 0;

        &:after { display: none; }

      }

    }

  }

  .info-text-img { display: none; }

  .column{

    &:nth-of-type(odd) {

      .info-link {

        .linkElement h4 a {
          background-color: rgba(117, 11, 7, 0.92);
          border-bottom: solid 1px #610505;
        }

        &:first-of-type .linkElement h4 a { border-top: solid 1px #610505; }

      }

    }

    &:nth-of-type(even) {

      .info-link {

        .linkElement h4 a {
          background-color: rgba(10, 28, 70, 0.62);
          border-bottom: solid 1px #1E3460;
        }

        &:first-of-type .linkElement h4 a { border-top: solid 1px #1E3460; }

      }

    }

  }

  .mobile-linkset-header h1 {
    color: $link-color;
    font-size: 20px;
    padding: 15px;
    font-weight: 600;
    text-align: center;
  }

}

.edit_mode .link-slider-container {

  .pageEl .textBlockElement img { max-width: 150px; }

}

.link-slider-slide {
  position: absolute;
  display: none;

  img {
    vertical-align: bottom;
    width: 100%;
  }

  .user_mode .link-slider-slides &:first-of-type,
  .edit_mode & {
    position: relative;
    display: block;
  }

  .leftTextImage,
  .rightTextImage {
    margin: 0;
    float: none;
  }

  .inner-text {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .inner-text-content {
    align-self: flex-end;
    width: 100%;
  }

  .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 50px 5%;
    background: rgba(0,0,0,.6);
    background: -webkit-linear-gradient(transparent, rgba(0,0,0,.6));
    background: -o-linear-gradient(transparent, rgba(0,0,0,.6));
    background: -moz-linear-gradient(transparent, rgba(0,0,0,.6));
    background: linear-gradient(transparent, rgba(0,0,0,.6));

    h1, h3, p, a { color: #fff; }

    h1 {
      text-transform: uppercase;
      font-size: 5vw;
      line-height: 4vw;
      margin-bottom: 15px;

      @media only screen and (max-width: 768px){
        font-size: 9vw;
        line-height: 8vw;
      }

    }

    h3 {
      font-size: 3vw;
      font-weight: 100;
      margin-bottom: 0;
    }

    p {
      font-weight: 300;
      margin-bottom: 10%;
      position: relative;

      &:after {
        content: '';
        display: block;
        height: 2px;
        width: 30px;
        background-color: $accent-color;
        left: 0;
        margin-top: 20px;
      }

    }

  }

}

// Container Background Image
.edit_mode .container-bg { display: block; }
.user_mode .container-bg { display: none; }

.has-container-bg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // background-attachment: fixed;
}

// Action Items / Home Page
.user_mode .action-items {

  .pageEl {
    padding: 40px 10%;

    @media only screen and (max-width: 768px){
      padding: 15px 10%;
      border-bottom: dotted 1px #cbc8c8;
    }

  }

  h3 {

    &:before {
      content: '';
      display: block;
      height: 75px;
      background-image: url('../images/icons/icon-action.svg');
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center;
      margin-bottom: 20px;
      fill: $link-color;
    }

    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 30px;
      background-color: $accent-color;
      margin: 20px calc(50% - 15px);
    }

  }

  .column:last-of-type .pageEl { border-bottom: none; }

  .icon-1 h3:before { background-image: url('../images/icons/icon-1.svg'); }
  .icon-2 h3:before { background-image: url('../images/icons/icon-2.svg'); }
  .icon-3 h3:before { background-image: url('../images/icons/icon-3.svg'); }

  h4 {
    color: $accent-color;
    margin-bottom: 10px;
  }

  a {
    color: $gray;
    text-transform: uppercase;
    padding: 10px 20px;
    border: solid 1px #b0b0b0;
    font-size: 13px;
    font-weight: 500;
    transition: all $transition-standard;
    margin-top: 30px;
    display: inline-block;

    &:hover {
      color: #ffffff;
      border: solid 1px $accent-color;
      background-color: $accent-color;
      text-decoration: none;
    }

  }

}

// Icon 4 | Since 2006
.user_mode .icon-4 {
  overflow: hidden;

  &:before {
    content: '';
    background-image: url('../images/icons/icon-4.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    width: 100%;
    height: 75px;
    margin: 75px 0 15px;
  }

}

// Sponsor Grid
.sponsor-grid {

  .edit_mode & img {
    max-width: 150px;
  }

  .sponsor-grid-block {
    padding-bottom: 30px;

    .pageEl {
      padding: 0 3%;
      transition: opacity $transition-standard;
      width: 20%;

      &:hover { opacity: .75; }

    }

    .sponsor-grid-block-center {
      width: auto;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      @media only screen and (max-width: 768px) { flex-wrap: wrap; }

    }

  }

  &.hat-trick .pageEl {
    width: 20%;

    @media only screen and (max-width: 768px) { width: 33.3333333%; }

    @media only screen and (max-width: 480px) { width: 50%; }

  }

  &.goal .pageEl {
    width: 16.6666667%;

    @media only screen and (max-width: 768px) { width: 25%; }

    @media only screen and (max-width: 640px) { width: 33.3333333%; }

  }

}

// Call-to-actions
.user_mode .sn-call-to-action-gradient {
  position: absolute;
  height: 100%;
  width: 100%;
  background: -webkit-linear-gradient(transparent,rgba(0,0,0,.6));
  background: -o-linear-gradient(transparent,rgba(0,0,0,.6));
  background: -moz-linear-gradient(transparent,rgba(0,0,0,.6));
  background: linear-gradient(transparent,rgba(0,0,0,.6));

  @media only screen and (max-width: 768px){
    background: -webkit-linear-gradient(transparent -100%,rgba(0,0,0,.6) 75%);
    background: -o-linear-gradient(transparent -100%,rgba(0,0,0,.6) 75%);
    background: -moz-linear-gradient(transparent -100%,rgba(0,0,0,.6) 75%);
    background: linear-gradient(transparent -100%,rgba(0,0,0,.6) 75%);
    border-bottom: solid 1px #acacac;
  }

}

// Custom Containers

.home #adWrapperTopLeaderboard {
  padding: 15px;
  margin: 0;
  background-color: #eeeeee;
}

// Social Container
.social-container {

  .user_mode & { padding-bottom: 100px; }

  .sn-social-media-list {
    text-align: center;
    margin-top: 0;
  }

}

.user_mode {

  $social-box-margin: calc((100% - 500px) / 2);

  .social-box .pageElement {
    padding: 15px $social-box-margin;
    background-color: #ffffff;
    border: solid 1px #dcdcdc;
    border-top: none;
    margin-top: 0;
  }

  .fb-page,
  .fb-page span,
  .fb-page span iframe[style],
  .fb-page ._2p3a {
      width: 100% !important;
  }

  .center-icons {
    background: #ffffff;
    border: solid 1px #dcdcdc;
    border-bottom: none;
    margin-top: 15px;

    .pageElement {
      margin-bottom: 0;
      padding-top: 5px;
    }

  }

}

// Custom Countdown
.user_mode .custom-count {

  // Left Column
  .column-1 {
    background-color: $link-color;
    padding: 25px 0 !important;

    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: calc(100% - 1px);
      top: 0;
      z-index: 2;
      border-top: 0 solid transparent;
      border-bottom: 74px solid transparent;
      border-left: 30px solid $link-color;
    }

    p { color: #fff; }

    .leftTextImage {
      position: absolute;
      width: 80px !important;
      left: 20px;
      margin: 0;
      float: none;
      min-height: 90px !important;
      top: -7px;
    }

    .text { padding: 0 10% 0 115px; }

  }
  // Center Column
  .column-2 {
    background-color: #dddddd;
    padding: 25px 0 !important;

    p { color: $link-color; }

    .text { padding: 0 10%; }

  }
  // Right Column
  .column-3 {
    background-color: #b8b8b8;

    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      right: calc(100% - 1px);
      top: 0;
      width: 0;
      height: 0;
      border-top: 74px solid transparent;
      border-bottom: 0 solid transparent;
      border-right: 30px solid #b8b8b8;
    }

    .countdownElement.countdown-dark {
      background: transparent;
      padding: 12px 0;

      &:before,
      &:after { display: none; }

      span {
        margin: 0;
        border-right: solid 1px #dbdbdb;
        padding: 0 10px;

        &:last-of-type { border-right: none; }

      }

    }

  }

  @media only screen and (max-width: 1260px){

    .column-1 {

      .leftTextImage { display: none; }

      .text {
        padding: 0 30px;
        text-align: center;
      }

    }

  }

  @media only screen and (max-width: 1000px){

    .column-1,
    .column-2,
    .column-3 { width: 100%; }

    .column-1:after,
    .column-3:after { display: none; }

    .column-2 {

      .text {
        padding: 0 30px;
        text-align: center;
      }

    }

  }

}
