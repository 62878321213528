#siteContainer {
  &, #siteContainer.container-fluid {
    padding: 15px 0;
    width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: #fff;
  }

  @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    padding: 15px 0;
    margin: 0 15px;
    font-size: 2em;
    font-weight: 400;
    border-bottom: $borderWidth solid $borderColor;
    text-transform: initial;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  #yieldContent.row { margin: auto; }

  #panelOne,
  #panelTwo { padding: 0; }

  #panelTwo {

    .column {
      @media only screen and (max-width: 768px){ width: 100% !important; }
    }
  }

  .yieldPageContent { padding:0; }
}

.user_mode.home {

  #siteContainer {
    &, #siteContainer.container-fluid { max-width: 100%; }
  }

}
