//---Accordion Styles---//

.user_mode {

  .accordion {

    h1, h2, h3, h4, h5, h6 { font-family: $fontTwo;  }

  }

  .accordion-header {
    background-color: #eeeeee;
    padding: 0 30px;
    border-bottom: solid 1px #cdcdcd;
    transition: all $transition-standard;

    &.accordion-open {
      border-bottom: solid 1px #eeeeee;

      &:after {
        color: $accent-color;
        transform: rotate(45deg);
      }

    }

    &:hover {
      cursor: pointer;
      background-color: #dfdfdf;
    }

    &:after {
      content: '\f067';
      position: absolute;
      right: 45px;
      top: 18px;
      height: 18px;
      width: 18px;
      line-height: 21px;
      text-align: center;
      color: #fff;
      font-family: FontAwesome;
      font-size: 12px;
      color: $gray;
      transition: all $transition-standard;
      border-radius: 100px;
      border: solid 2px;

      @media only screen and (max-width: 1024px){ line-height: 19px; }

    }

    .textBlockElement {

      h1, h2 {
        display: inline-block;
        font-size: 20px;
      }

      h1 {
        color: $accent-color;
        font-weight: 800;
      }

      h2 {
        color: $gray;
        font-weight: 300;
      }

      h3 {
        margin-bottom: 0;
        color: $gray;
        font-weight: 300;
        letter-spacing: .5px;
        padding-right: 50px;
      }

    }

  }

  .accordion-body {
    display: none;
    background-color: #eeeeee;
    padding: 0 30px 15px;
    border-bottom: solid 1px #cdcdcd;
  }
}
